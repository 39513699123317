'use strict';

import $ from 'jquery';
import { Plugin } from '../../../../../node_modules/foundation-sites/js/foundation.plugin';
import { GetYoDigits } from '../../../../../node_modules/foundation-sites/js/foundation.util.core';
import 'slick-carousel';


class Slider extends Plugin {
    _setup(element, options) {
        this.$element = element;

        if(this.$element.data().responsiveMode == 1){
            Slider.defaults = $.extend({}, Slider.defaults, responsive_1);
        } else if(this.$element.data().responsiveMode == 2){
            Slider.defaults = $.extend({}, Slider.defaults, responsive_2);
        }


        this.options = $.extend({}, Slider.defaults, this.$element.data(), options);

        this.id = this.$element[0].id;
        var id = this.$element[0].id || GetYoDigits(6, 'slider');
        // this.$slidesDottes = this.$element.find(`.${this.options.slidesDottesClass}`).clone();
        this.$slider = this.$element.find(`.${this.options.sliderClass}`) || this.$element;

        // this.slidesDottesArray = this.$slidesDottes.children().clone();
        this.$element.attr({
            'id': id
        });
        this._init();

    }
    _init() {
        var _this = this;
        // if (typeof this.$slidesDottes !== "undefined") {
        //     this.options.customPaging = function(slider, i) {
        //         return _this.slidesDottesArray[i];
        //     };
        // }
        this.$slider.slick(this.options);
        this._events();
    }

    _events() {
        var _this = this;
        this.$slider.on('beforeChange', function(event, slick, currentSlide, nextSlide) {
            $('.slick-current').addClass('slick-fading');

        });
        this.$slider.on('afterChange', function(event, slick, currentSlide, nextSlide) {
            $('.slick-slide').removeClass('slick-fading')
        });
    }

}
Slider.defaults = {
    slidesToShow: 3,
    slidesToScroll: 3,
    adaptiveHeight: false,
    infinite: false,
    rows: 0,
    dots: true,
    centerPadding: '240px',
    prevArrow: $('#previous_button'),
    nextArrow: $('#next_button'),
    responsiveMode: 1,

    /**
     * Class applied to individual slider.
     * @option
     * @type {string}
     * @default 'slick-slide'
     */
    sliderClass: 'slider',
    /**
     * Class applied to individual slides.
     * @option
     * @type {string}
     * @default 'slick-slide'
     */
    slideClass: 'slide',
    /**
     * Class applied to individual slider-dottes.
     * @option
     * @type {string}
     * @default 'slick-slide'
     */
    slidesDottesClass: 'slider-dottes'
}

const responsive_1 = { responsive: [{
        breakpoint: 480,
        settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            centerMode: false,
        }
    },

    {
        breakpoint: 600,
        settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            centerMode: false,
            centerPadding: '0px',
        }
    },
    {
        breakpoint: 1050,
        settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
            centerMode: true,
            initialSlide: true,
            centerPadding: '0px',
        }
    },
    {
        breakpoint: 1550,
        settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            centerMode: false,
            centerPadding: '0px',
        }
    }]
}

const responsive_2 = { responsive: [{
        breakpoint: 600,
        settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            centerMode: false,
        }
    },
    {
        breakpoint: 850,
        settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            centerMode: true,
            centerPadding: '120px',
        }
    },
    {
        breakpoint: 1550,
        settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            centerMode: true,
            centerPadding: '200px',
        }
    }]
}

export { Slider };
