import $ from 'jquery';
import whatInput from 'what-input';

window.$ = $;

// import Foundation from 'foundation-sites';
// If you want to pick and choose which modules to include, comment out the above and uncomment
// the line below
import './lib/foundation-explicit-pieces';

// import 'tablesaw/dist/tablesaw.jquery';
import libs from './lib/dependancies';
window.libs = libs;

$(document).foundation();

libs.AOS.init();

// SVG Injector
// Elements to inject
var mySVGsToInject = document.querySelectorAll('svg.inject-me');

// Options
var injectorOptions = {
    evalScripts: 'once',
    pngFallback: 'assets/png'
};

//open navbar-medium on site load
// new Foundation.OffCanvas($("#navbar-medium")).open();

var afterAllInjectionsFinishedCallback = function(totalSVGsInjected) {
    // Callback after all SVGs are injected
    // console.log('We injected ' + totalSVGsInjected + ' SVG(s)!');
    $(window).trigger('resize');
};

var perInjectionCallback = function(svg) {
    // Callback after each SVG is injected
    // console.log('SVG injected: ' + svg);
};

// create injector configured by options
var injector = new libs.svgInjector(injectorOptions);

// Trigger the injection
injector.inject(
    mySVGsToInject,
    afterAllInjectionsFinishedCallback,
    perInjectionCallback
);

// Slick slider display none solution
$('.slick-trigger').on("click", function() {
    function show_slider(){
        $('.slider').slick('setPosition');
    }
    window.setTimeout( show_slider, 10 );
});
